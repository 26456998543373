import React from 'react'
import PropTypes from 'prop-types'

import TextBox from '@atoms/TextBox'

import { Wrapper, Content, Image } from './styles'

const ImageText = ({ alt, image, children }) => {
  return (
    <Wrapper>
      <Content>
        <TextBox center>{children}</TextBox>
      </Content>
      <Image>{image && <img src={image} alt={alt ? alt : ''} />}</Image>
    </Wrapper>
  )
}

ImageText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default ImageText
