import React from 'react'
import { graphql } from 'gatsby'

import Main from '@templates/Main'
import TextBox from '@atoms/TextBox'
import SectionGrayMiddle from '@molecules/SectionGrayMiddle'
import SectionWhite from '@molecules/SectionWhite'
import SignupCTA from '@organisms/SignupCTA'
import FooterCTA from '@organisms/FooterCTA'
import ImageText from '@molecules/ImageText'

import { features } from '@content/features'

const FeaturesPage = ({ data, transitionStatus }) => {
  const pageData = data.pageData

  return (
    <Main seo={pageData.seoMetaTags} transitionStatus={transitionStatus}>
      <SectionGrayMiddle hero>
        <TextBox title="Features" center>
          <h2>Let's take another step</h2>
          <p>Check out features brought to you by ideolo.</p>
        </TextBox>
      </SectionGrayMiddle>
      <SectionWhite>
        {features.map(feature => (
          <ImageText key={feature.id} image={feature.image}>
            <strong>{feature.short}</strong>
            <h3>{feature.header}</h3>
            {feature.subheader !== '' && <h4>{feature.subheader}</h4>}
            <p>{feature.text}</p>
          </ImageText>
        ))}
      </SectionWhite>
      <SignupCTA bgColor="gray" />
      <FooterCTA />
    </Main>
  )
}

export const query = graphql`
  query FeaturesPageQuery {
    pageData: datoCmsFeaturesPage {
      seoMetaTags {
        tags
      }
    }
  }
`

export default FeaturesPage
