import styled from 'styled-components'

export const Wrapper = styled.section`
  z-index: 1000;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 60px 0;
  background: var(--bg-white-color);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;

    > svg {
      margin: 0 auto;
      width: 80%;
      height: auto;
    }
  }

  ${({ theme }) => theme.media.m} {
    flex-direction: row;

    > div {
      width: 50%;
    }

    &:nth-child(even) {
      > div:nth-child(1) {
        order: 2;
      }
    }
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h3 {
    margin: 0 0 15px;
    font-size: 32px;
  }

  h4 {
    font-size: 24px;
  }

  p {
    margin: 0 auto 15px;
    max-width: 600px;
    font-size: 18px;
    line-height: 1.5;
  }
`

export const Image = styled.div`
  img {
    margin: 0 auto;
    width: 100%;
  }

  ${({ theme }) => theme.media.m} {
    img {
      width: 80%;
    }
  }
`
